import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  messages: {},
};

const translationSlice = createSlice({
  name: 'translations',
  initialState,
  reducers: {
    setMessages: (state, action) => {
      state.messages = action.payload;
    },
  },
});

export const { setMessages } = translationSlice.actions;
export default translationSlice.reducer;
