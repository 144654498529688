import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'I7GgD+',
    defaultMessage: 'Performance Calculator',
  },
  introduction: {
    id: 'BubxhN',
    defaultMessage:
      'Speed is what matters: Accelerate your data transfers by up to 100x with IBM Aspera',
  },
  downloadButton: {
    id: 'YocjIT',
    defaultMessage: 'Download White Paper',
  },
  requestDemo: {
    id: 'olK/t4',
    defaultMessage: 'Request a live demo',
  },
  chart1Text: {
    id: 'IUJKDE',
    defaultMessage: 'Aspera',
  },
  chart2Text: {
    id: 'zVg++A',
    defaultMessage: 'TCP',
  },
  bp_title: {
    id: 'BkHBGW',
    defaultMessage: 'Selected Business Partner',
  },
  thank_text: {
    id: 'aYIUar',
    defaultMessage: 'Thank you!',
  },
  modalContinue: {
    id: 'rbrahO',
    defaultMessage: 'Close',
  },
  footnote: {
    id: 'gzN6/W',
    defaultMessage:
      'Want to learn more about how we gathered and analyzed this data? Download our white paper for an in-depth look.',
  },
  requestWhitepaper: {
    id: 'P1jLdx',
    defaultMessage: 'Download eBook',
  },
  file_size_dropdown_label: {
    id: 'hDfkrK',
    defaultMessage: 'File Size',
  },
  network_bandwidth_dropdown_label: {
    id: 'wrDs07',
    defaultMessage: 'Network Bandwidth',
  },
  latency_dropdown_label: {
    id: '2n9biM',
    defaultMessage: 'Latency',
  },
  packet_loss_dropdown_label: {
    id: '/bhcYN',
    defaultMessage: 'Packet Loss',
  },
  transfer_from_dropdown_label: {
    id: 'IyqJFI',
    defaultMessage: 'Transfer From (Location)',
  },
  transfer_to_dropdown_label: {
    id: '+V0Ekk',
    defaultMessage: 'Transfer To (Location)',
  },
  chart1_label: {
    id: 'pT5WS0',
    defaultMessage: 'File Transfer Time',
  },
  chart2_label: {
    id: '0Nwt8h',
    defaultMessage: 'Bandwidth Utilization',
  },
  advantage_title: {
    id: 'E6po0b',
    defaultMessage: 'The IBM Aspera Advantage',
  },
  advantage_text1: {
    id: 'UonnuG',
    defaultMessage: 'more throughput',
  },
  advantage_text2: {
    id: 'EI5vK3',
    defaultMessage: 'faster',
  },
  advantage_text3: {
    id: 'VDdIdM',
    defaultMessage: 'less total time',
  },
  button_title: {
    id: 'yF3VHT',
    defaultMessage: 'Ready to try Aspera?',
  },
  button_text: {
    id: '1OBVa8',
    defaultMessage: 'Start a trial today',
  },
  mode1_text: {
    id: 'xAaoY9',
    defaultMessage: 'Base Mode',
  },
  mode2_text: {
    id: 'WQgU/7',
    defaultMessage: 'Advanced Mode',
  },
  calculate_button_text: {
    id: 'Kvfc5f',
    defaultMessage: 'Calculate ROI',
  },
  transfer_time_title: {
    id: 'pT5WS0',
    defaultMessage: 'File Transfer Time',
  },
  throughput_title: {
    id: 'y5l0AH',
    defaultMessage: 'Throughput',
  },
  bandwidth_utilization_title: {
    id: '0Nwt8h',
    defaultMessage: 'Bandwidth Utilization',
  },
  faster_title: {
    id: 'oiUXH+',
    defaultMessage: 'Faster!',
  },
  transfer_time_text_1: {
    id: 'HzM/pN',
    defaultMessage: 'compared to ',
  },
  transfer_time_text_2: {
    id: 'GGuDck',
    defaultMessage: ' using traditional TCP',
  },
  throughput_text: {
    id: 'SMHKEi',
    defaultMessage: 'faster than traditional TCP',
  },
  bandwidth_utilization_text_2: {
    id: 'rjuZnA',
    defaultMessage: ' utilization with traditional TCP',
  },
  whitepaper_button_text: {
    id: 'PkSWbv',
    defaultMessage: 'Read our whitepaper',
  },
  latency_label: {
    id: 'yJeVDy',
    defaultMessage: 'Latency (in milliseconds)',
  },
  ploss_label: {
    id: 'Ox6UEd',
    defaultMessage: 'Packet loss (percentage)',
  },
  disclaimer: {
    id: '8XbEpY',
    defaultMessage:
      'Transfer time, throughput, and bandwidth utilization values are estimates based on Aspera technology compared to traditional TCP methods under ideal conditions. Actual results may vary based on network conditions, file size, and bandwidth availability.',
  },
  description: {
    id: 'aTHuJu',
    defaultMessage:
      'Curious how fast you can move large files or datasets? Try our calculator and see how Aspera outperforms, even over challenging networks. Experience the speed difference today.',
  },
  additional_text_1: {
    id: 'E8mOsm',
    defaultMessage:
      'High-Speed file transfers regardless of the size and location with proprietary FASP technology.',
  },
  additional_text_2: {
    id: 'MH9sMv',
    defaultMessage:
      'Robust set of APIs and SDK to help you get up and running and integrate Aspera into existing workflows.',
  },
  additional_text_3: {
    id: 'ZhzCFt',
    defaultMessage: 'End to end security to protect data during transfer.',
  },
  received_text: {
    id: '22XI4X',
    defaultMessage:
      'We have received your request and a member of our Aspera team will contact you shortly.',
  },
  thank_you: {
    id: 'v5ZYSx',
    defaultMessage: 'Thank You!',
  },
  req_detail: {
    id: 'v/FY4E',
    defaultMessage: 'Details:',
  },
  return_est_text: {
    id: '552lEp',
    defaultMessage: 'Return to product page',
  },
  thanks_faster: {
    id: '9dx43B',
    defaultMessage: 'Faster',
  },
});

export default messages;
