import React, { useEffect } from 'react';
import { IntlProvider, ResolvedIntlConfig } from 'react-intl';
import { useDispatch } from 'react-redux';
import { setMessages } from '../../lib/redux/slices/translationSlice';

interface TranslationProviderProps {
  children: React.ReactNode;
  messages: ResolvedIntlConfig['messages'] | Record<string, string>;
}

const url = window.location.href;
// Domains that need to use React Intl / FormattedMessage component
export const isAEMEnv = !(
  url.includes('storage.appdomain.cloud') ||
  url.includes('intranet.ibm.com') ||
  url.includes('hc-widgets.ibm.com')
);

export const TranslationProvider: React.FC<TranslationProviderProps> = ({
  children,
  messages,
}) => {
  const dispatch = useDispatch();

  // will set fetched messages into Redux to use in any component
  useEffect(() => {
    if (isAEMEnv) {
      dispatch(setMessages(messages));
    }
  }, []);

  return isAEMEnv ? (
    <>{children}</>
  ) : (
    <IntlProvider messages={messages} locale="en" defaultLocale="en">
      {children}
    </IntlProvider>
  );
};
