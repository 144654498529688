import mapValidLocale, { IBMLocale } from './mapValidLocale';

export type AEMPromise = Promise<Record<string, string>>;

function getLocale(locale: string): string {
  switch (locale) {
    case IBMLocale.DE:
      return 'de-de';
    case IBMLocale.ES:
      return 'es-es';
    case IBMLocale.ESLA:
      // Handle 'esla' as 'es-es'
      return 'es-mx';
    case IBMLocale.FR:
      return 'fr-fr';
    case IBMLocale.IT:
      return 'it-it';
    case IBMLocale.JA:
      return 'jp-ja';
    case IBMLocale.KO:
      return 'kr-ko';
    case IBMLocale.PT:
      return 'br-pt';
    case IBMLocale.ID:
      return 'id-id';
    case IBMLocale.AR:
      // to handle arabic verbiages
      return 'sa-ar';
    case IBMLocale.ZH_CN:
      // Handle 'zh-cn' as 'cn-zh'
      return 'cn-zh';
    default:
      console.warn(`Unknown Widget language code: ${locale}`);
      return '';
  }
}
const fetchAEMTranslationMessages = async (
  widgetId: string,
  langCode: string,
): Promise<AEMPromise> => {
  const locale = mapValidLocale(langCode);

  if (locale === IBMLocale.EN) {
    try {
      const response = await window.fetch(
        `https://www.ibm.com/widgets-admin/${widgetId}/jcr:content.widget.json`,
      );
      return response.json();
    } catch (e: any) {
      return {
        message: `${e.error.message}`,
      };
    }
  }
  try {
    const response = await window.fetch(
      `https://www.ibm.com/${getLocale(
        locale,
      )}/widgets-admin/${widgetId}/jcr:content.widget.json`,
    );
    return response.json();
  } catch (e: any) {
    return {
      message: `${e.error.message}`,
    };
  }
};

export default fetchAEMTranslationMessages;
