import { useState, ReactElement, useEffect } from 'react';
import { useIntl } from 'react-intl';
import messages from '../../locales/messages';
import 'src/common/style/common.scss';
import { ThanksModal, thanksTable } from 'src/common/utils/ThanksModal';

export interface thanksProps {
  fileSize: string;
  networkBandwidth: string;
  transferFrom: string;
  transferTo: string;
  latency: number;
  packetLoss: number;
  asperaTransferTime: string;
  bandwidthUtilization: string;
  throughPut: string;
  faster: string;
  bpName: string;
}

const Thanks = ({
  fileSize,
  networkBandwidth,
  transferFrom,
  transferTo,
  latency,
  packetLoss,
  bpName,
}: thanksProps): ReactElement => {
  const intl = useIntl();
  const [showThanksModal, setShowThanksModal] = useState(true);
  const titleThanks: thanksTable[] = [
    { group: 'title', value: intl.formatMessage(messages.thank_you) },
    {
      group: 'subTitle',
      value: intl.formatMessage(messages.received_text),
    },
  ];

  const widgetTitleThanks: thanksTable[] = [
    {
      group: 'widgetTitle1',
      value: '',
    },
  ];

  const contentThanks: thanksTable[] = [];

  bpName &&
    contentThanks.push({
      group: String(intl.formatMessage(messages.bp_title)),
      value: bpName,
    });

  const buttonThanks: thanksTable[] = [
    {
      group: 'button1',
      value: String(intl.formatMessage(messages.return_est_text)),
    },
  ];

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(true);
  }, [
    fileSize,
    networkBandwidth,
    transferFrom,
    transferTo,
    latency,
    packetLoss,
  ]);
  return (
    <ThanksModal
      showThanksModal={showModal}
      pageTitle="Aspera File Transfer Page"
      object="File Transfer Page"
      productTitle={'Aspera File Transfer'}
      pageUrl={'/products/aspera/file-transfer/'}
      location={'File Transfer'}
      titleThanks={titleThanks}
      widgetTitleThanks={widgetTitleThanks}
      contentThanks={contentThanks}
      buttonThanks={buttonThanks}
      setShowThanksModal={setShowThanksModal}
    />
  );
};

export default Thanks;
