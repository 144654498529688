import { ReactElement, useState, useEffect } from 'react';
import { Modal, ModalBody, Link } from 'carbon-components-react';
import '../style/thanksModal.scss';
import { segmentsTracking } from '../lib/tracker';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { DocumentPdf32, Launch32 } from '@carbon/icons-react';

export interface thanksTable {
  group: string;
  value: string;
}

export interface ThanksTableVerbiage {
  group: string | JSX.Element;
  value: string | JSX.Element;
}

export interface ThanksModalProperties {
  showThanksModal: boolean;
  pageTitle: string;
  object: string;
  productTitle: string;
  pageUrl: string;
  location: string;
  titleThanks: ThanksTableVerbiage[];
  widgetTitleThanks: ThanksTableVerbiage[];
  contentThanks: ThanksTableVerbiage[];
  contentThanks2?: ThanksTableVerbiage[];
  buttonThanks: ThanksTableVerbiage[];
  pdfExport?: boolean;
  pdfArray?: thanksTable[];
  pdfObject?: any;
  setShowThanksModal: React.Dispatch<React.SetStateAction<boolean>>;
  link?: { demo: string; demoText: string };
}

export const ThanksModal = ({
  showThanksModal,
  pageTitle,
  object,
  productTitle,
  pageUrl,
  location,
  titleThanks,
  widgetTitleThanks,
  contentThanks,
  contentThanks2,
  buttonThanks,
  pdfExport,
  pdfArray,
  pdfObject,
  setShowThanksModal,
  link,
}: ThanksModalProperties): ReactElement => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(showThanksModal);
  }, [showThanksModal]);

  return (
    <div className="thanksModal">
      <div>
        <Modal
          open={showModal}
          modalHeading={titleThanks[0].value}
          primaryButtonText={buttonThanks[0].value}
          onRequestClose={() => {
            segmentsTracking('CTA Clicked', {
              productTitle,
              pageTitle,
              object,
              pageUrl,
              resultValue: 'CTA clicked',
              CTA: 'Feedback closed on the corner',
              location,
              action: `Thank you modal closed`,
              field: '',
            });
            setShowModal(false);
            setShowThanksModal(false);
            window.location.reload();
          }}
          onRequestSubmit={() => {
            segmentsTracking('CTA Clicked', {
              productTitle,
              pageTitle,
              object,
              pageUrl,
              resultValue: 'CTA clicked',
              CTA: 'Feedback submitted',
              location,
              action: `Thank you modal closed`,
              field: '',
            });
            setShowModal(false);
            window.location.reload();
          }}
        >
          <ModalBody hasForm className="ModalBody">
            <div className="bx--row nam">
              <div className="bx--col-lg-16 bx--col-md-8 bx--col-sm-4">
                {titleThanks[1].value ? (
                  <div className="mar5">
                    <span className="page_sub_heading_2">
                      {titleThanks[1].value}
                    </span>
                    <br />
                  </div>
                ) : null}
                {widgetTitleThanks[0].value ? (
                  <div>
                    <span className="page_body_2">
                      {widgetTitleThanks[0].value}
                    </span>
                    <br />
                    <br />
                  </div>
                ) : null}
                {contentThanks.map((content) => (
                  <div>
                    <span className="slide_text">{content.group}</span> : {` `}
                    <span className="slide_text">
                      <strong>{content.value}</strong>
                    </span>
                    <br />
                  </div>
                ))}
                {contentThanks2 && (
                  <div>
                    <br />
                    <br />
                    <p className="mar5">
                      <span className="page_body_2">
                        {widgetTitleThanks[1].value}
                      </span>
                    </p>
                  </div>
                )}
                {contentThanks2?.map((content) => (
                  <div>
                    <span className="slide_text">{content.group}</span> : {` `}
                    <span className="slide_text">
                      <strong>{content.value}</strong>
                    </span>
                    <br />
                  </div>
                ))}
                {pdfExport ? (
                  <PDFDownloadLink
                    document={pdfObject}
                    fileName={pdfArray ? pdfArray[0].value : ''}
                  >
                    <p className="blue-highlight">
                      <u>
                        {pdfArray ? pdfArray[0].group : ''}
                        <DocumentPdf32 className="load_icon" />
                      </u>
                    </p>
                  </PDFDownloadLink>
                ) : null}
                {link?.demo ? (
                  <div>
                    <Link href={link.demo} target="_blank" rel="noreferrer">
                      <span className="blue-highlight">
                        {link.demoText}
                        <Launch32 className="link-load_icon" />
                      </span>
                    </Link>
                  </div>
                ) : null}
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </div>
  );
};
