// UT30 is an unique code to identify each widget in the BPPR ecosystem
export const UT30AsperaFileTransferCalc = '30AHI';
export const data1 = [{ group: 'value', value: 45 }];
export const data2 = [{ group: 'value', value: 85 }];
export const data3 = [
  { group: 'TCP', value: 3 },
  { group: 'Aspera', value: 93 },
];
export const gauge1 = { numberFormatter: 1234, showPercentageSymbol: false };
export const link =
  'https://community.ibm.com/community/user/aiops/communities/community-home?communitykey=5a69b903-4d5f-4aaa-a21d-018ab33b60e2';

// Maximum Transmission Unit in bytes
export const MTU = 1500;

export const countryLookUp = [
  { name: 'India', code: 'in' },
  { name: 'Europe', code: 'eu' },
  { name: 'Africa', code: 'af' },
  { name: 'Australia', code: 'au' },
  { name: 'China', code: 'ch' },
  { name: 'Middle East', code: 'me' },
  { name: 'US', code: 'us' },
  { name: 'Latin America', code: 'la' },
  { name: 'Asia', code: 'as' },
  { name: 'Metropolitan Area Network', code: 'metro' },
];

export const routeLocations = [
  { country: 'metro', latency: 10, packetLoss: 0.001 },
  { country: 'af_af', latency: 100, packetLoss: 2 },
  { country: 'af_as', latency: 300, packetLoss: 4.6 },
  { country: 'af_au', latency: 470, packetLoss: 4.5 },
  { country: 'af_in', latency: 290, packetLoss: 6.3 },
  { country: 'af_ch', latency: 170, packetLoss: 7.4 },
  { country: 'af_eu', latency: 170, packetLoss: 4.2 },
  { country: 'af_la', latency: 220, packetLoss: 6.3 },
  { country: 'af_me', latency: 220, packetLoss: 4.7 },
  { country: 'af_us', latency: 260, packetLoss: 4.5 },
  { country: 'as_as', latency: 65, packetLoss: 0.2 },
  { country: 'as_af', latency: 300, packetLoss: 4.6 },
  { country: 'as_au', latency: 130, packetLoss: 0.53 },
  { country: 'as_in', latency: 140, packetLoss: 2.5 },
  { country: 'as_ch', latency: 230, packetLoss: 3.5 },
  { country: 'as_eu', latency: 230, packetLoss: 0.64 },
  { country: 'as_la', latency: 240, packetLoss: 2.7 },
  { country: 'as_me', latency: 240, packetLoss: 1.1 },
  { country: 'as_us', latency: 180, packetLoss: 0.79 },
  { country: 'au_au', latency: 30, packetLoss: 0.002 },
  { country: 'au_af', latency: 470, packetLoss: 4.5 },
  { country: 'au_as', latency: 130, packetLoss: 0.53 },
  { country: 'au_in', latency: 220, packetLoss: 4.2 },
  { country: 'au_ch', latency: 240, packetLoss: 6.2 },
  { country: 'au_eu', latency: 300, packetLoss: 0.5 },
  { country: 'au_la', latency: 400, packetLoss: 4.4 },
  { country: 'au_me', latency: 280, packetLoss: 1.3 },
  { country: 'au_us', latency: 220, packetLoss: 0.63 },
  { country: 'in_in', latency: 40, packetLoss: 2 },
  { country: 'in_af', latency: 290, packetLoss: 6.3 },
  { country: 'in_as', latency: 140, packetLoss: 2.5 },
  { country: 'in_au', latency: 220, packetLoss: 4.2 },
  { country: 'in_ch', latency: 150, packetLoss: 7.3 },
  { country: 'in_eu', latency: 150, packetLoss: 4.1 },
  { country: 'in_la', latency: 150, packetLoss: 6.3 },
  { country: 'in_me', latency: 150, packetLoss: 4.7 },
  { country: 'in_us', latency: 270, packetLoss: 4.5 },
  { country: 'ch_ch', latency: 220, packetLoss: 3 },
  { country: 'ch_af', latency: 170, packetLoss: 7.4 },
  { country: 'ch_as', latency: 230, packetLoss: 3.5 },
  { country: 'ch_au', latency: 240, packetLoss: 6.2 },
  { country: 'ch_in', latency: 150, packetLoss: 7.3 },
  { country: 'ch_eu', latency: 220, packetLoss: 6.2 },
  { country: 'ch_la', latency: 200, packetLoss: 8.3 },
  { country: 'ch_me', latency: 200, packetLoss: 6.7 },
  { country: 'ch_us', latency: 210, packetLoss: 6.4 },
  { country: 'eu_eu', latency: 30, packetLoss: 0.035 },
  { country: 'eu_af', latency: 170, packetLoss: 4.2 },
  { country: 'eu_as', latency: 230, packetLoss: 0.64 },
  { country: 'eu_au', latency: 300, packetLoss: 0.5 },
  { country: 'eu_in', latency: 150, packetLoss: 4.1 },
  { country: 'eu_ch', latency: 220, packetLoss: 6.2 },
  { country: 'eu_la', latency: 110, packetLoss: 2.2 },
  { country: 'eu_me', latency: 110, packetLoss: 0.61 },
  { country: 'eu_us', latency: 140, packetLoss: 0.1 },
  { country: 'la_la', latency: 280, packetLoss: 2 },
  { country: 'la_af', latency: 220, packetLoss: 6.3 },
  { country: 'la_as', latency: 240, packetLoss: 2.7 },
  { country: 'la_au', latency: 400, packetLoss: 4.4 },
  { country: 'la_in', latency: 150, packetLoss: 6.3 },
  { country: 'la_ch', latency: 200, packetLoss: 8.3 },
  { country: 'la_eu', latency: 110, packetLoss: 2.2 },
  { country: 'la_me', latency: 280, packetLoss: 4.8 },
  { country: 'la_us', latency: 150, packetLoss: 4.4 },
  { country: 'me_me', latency: 80, packetLoss: 0.5 },
  { country: 'me_af', latency: 220, packetLoss: 4.7 },
  { country: 'me_as', latency: 240, packetLoss: 1.1 },
  { country: 'me_au', latency: 280, packetLoss: 1.3 },
  { country: 'me_in', latency: 150, packetLoss: 4.7 },
  { country: 'me_ch', latency: 200, packetLoss: 6.7 },
  { country: 'me_eu', latency: 110, packetLoss: 0.61 },
  { country: 'me_la', latency: 280, packetLoss: 4.8 },
  { country: 'me_us', latency: 200, packetLoss: 1.4 },
  { country: 'us_us', latency: 40, packetLoss: 0.24 },
  { country: 'us_af', latency: 260, packetLoss: 4.5 },
  { country: 'us_as', latency: 180, packetLoss: 0.79 },
  { country: 'us_au', latency: 220, packetLoss: 0.63 },
  { country: 'us_in', latency: 270, packetLoss: 4.5 },
  { country: 'us_ch', latency: 210, packetLoss: 6.4 },
  { country: 'us_eu', latency: 140, packetLoss: 0.1 },
  { country: 'us_la', latency: 150, packetLoss: 4.4 },
  { country: 'us_me', latency: 200, packetLoss: 1.4 },
];

export const fileSizes = [
  {
    id: 'Q1A1',
    label: '100 MB',
  },
  {
    id: 'Q1A2',
    label: '250 MB',
  },
  {
    id: 'Q1A3',
    label: '500 MB',
  },
  {
    id: 'Q1A4',
    label: '1 GB',
  },
  {
    id: 'Q1A5',
    label: '10 GB',
  },
  {
    id: 'Q1A6',
    label: '100 GB',
  },
  {
    id: 'Q1A7',
    label: '500 GB',
  },
  {
    id: 'Q1A8',
    label: '1 TB',
  },
];

export const networkBandwidths = [
  {
    id: 'Q2A1',
    label: '10 Mbps',
  },
  {
    id: 'Q2A2',
    label: '45 Mbps',
  },
  {
    id: 'Q2A3',
    label: '100 Mbps',
  },
  {
    id: 'Q2A4',
    label: '300 Mbps',
  },
  {
    id: 'Q2A5',
    label: '500 Mbps',
  },
  {
    id: 'Q2A6',
    label: '1 Gbps',
  },
  {
    id: 'Q2A7',
    label: '2.5 Gbps',
  },
  {
    id: 'Q2A8',
    label: '5 Gbps',
  },
  {
    id: 'Q2A9',
    label: '10 Gbps',
  },
];

export const LocationsFrom = [
  {
    id: 'Q5A1',
    label: 'US',
  },
  {
    id: 'Q5A2',
    label: 'Europe',
  },
  {
    id: 'Q5A3',
    label: 'India',
  },
  {
    id: 'Q5A4',
    label: 'Asia',
  },
  {
    id: 'Q5A5',
    label: 'Latin America',
  },
  {
    id: 'Q5A6',
    label: 'Africa',
  },
  {
    id: 'Q5A7',
    label: 'Middle East',
  },
  {
    id: 'Q5A8',
    label: 'Metropolitan Area Network',
  },
  {
    id: 'Q5A9',
    label: 'China',
  },
  {
    id: 'Q5A10',
    label: 'Australia',
  },
  {
    id: 'Q5A11',
    label: 'Custom',
  },
];

export const LocationsTo = [
  {
    id: 'Q6A1',
    label: 'US',
  },
  {
    id: 'Q6A2',
    label: 'Europe',
  },
  {
    id: 'Q6A3',
    label: 'India',
  },
  {
    id: 'Q6A4',
    label: 'Asia',
  },
  {
    id: 'Q6A5',
    label: 'Latin America',
  },
  {
    id: 'Q6A6',
    label: 'Africa',
  },
  {
    id: 'Q6A7',
    label: 'Middle East',
  },
  {
    id: 'Q6A8',
    label: 'Metropolitan Area Network',
  },
  {
    id: 'Q6A9',
    label: 'China',
  },
  {
    id: 'Q6A10',
    label: 'Australia',
  },
  {
    id: 'Q6A11',
    label: 'Custom',
  },
];
