module.exports = {
  entry: './src/apps/aspera-file-transfer-calculator/index.tsx',
  shortcode: 'aspera-file-transfer-calculator',
  title: 'Aspera File Transfer Calc',
  status: 'stable',
  description: 'Allows users to calculate file transfer parameters.',
  preview: {
    url: `${process.env.PUBLIC_URL}/storybook/iframe.html?id=widgets-aspera-file-transfer-calculator-components--default&viewMode=story`,
  },
  useExternalPeerDependencies: ['react', 'react-dom', 'react-intl'],
  additionalCustomProperties: {
    webSegmentPaths: ['/file-transfer-calculator'],
    availableTranslations: [
      'de',
      'en',
      'es',
      'fr',
      'it',
      'ja',
      'ko',
      'pt',
      'zh-cn',
    ],
    org: 'digitalsales', // to filter the widget
    widgetLocation: '/file-transfer-calculator', // default '/pricing' but sometimes
    productName: 'aspera', // the product name in the URL.
    activatedCountryPages: ['us-en'],
    brand: 'automation',
  },
};
