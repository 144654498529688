import { WidgetMetadataBasic } from '../../types/widgets';

// add any widget shortcode to this list that needs a direct pass-through of translations from AEM
export const widgetShortcodes = new Set<WidgetMetadataBasic['shortcode']>([
  'qradar-edr-estimator',
  'maas360-estimator',
  'ns1-roi',
  'ns1-pe',
  'spss-dynamic-pricing',
  'pa-roi',
  'watsonx-orchestrate-self-assessment',
  'ai-solutions-advisor',
]);
